import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FC } from 'react';
import { OptionProps, components } from 'react-select';
import styled from 'styled-components';

import { ILocaleOption } from './types';

const CustomOption: FC<OptionProps<ILocaleOption, false>> = (props) => {
  const data = props.data as ILocaleOption;

  return (
    <components.Option className="lang-switcher__option" {...props}>
      <SOption
        className={cn({ isSelected: props.isSelected, isFocused: props.isFocused })}
      >
        <SFlag flagSrc={data.flagSrc} />

        <span className="option__label">{props.label}</span>

        {props.isSelected ? (
          <SFontAwesomeIcon size="1x" icon={faCheck} color="#00a5d7" />
        ) : null}
      </SOption>
    </components.Option>
  );
};

const SOption = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
  transform: none;
  background: transparent;
  transition: background 0.3s;
  border-radius: 4px;
  border: 3px solid transparent;
  padding: 8px 12px;

  &:hover {
    border: 3px solid transparent !important;
    background: rgba(0, 0, 0, 0.05);
  }

  &.isSelected {
    font-weight: 600;
    background: rgba(0, 0, 0, 0.05);
  }

  &.isFocused {
    border: 3px solid var(--color-blue-btn-focus-outline-color);
  }

  .option__label {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-black);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
  }
`;

const SFlag = styled.div<{ flagSrc: string }>`
  display: inline-block;
  background-image: url(${(props) => props.flagSrc});
  margin-inline-end: 8px;
  height: 25px;
  width: 25px;
  min-width: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const SFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 8px;
  top: 12px;
`;

export default CustomOption;
