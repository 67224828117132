import { t } from 'i18n';
import React from 'react';
import { SingleValueProps, components } from 'react-select';
import styled from 'styled-components';
import { ILocaleOption } from './types';

const CustomSingleValue: React.FC<SingleValueProps<ILocaleOption>> = (props) => (
  <SSingleValue
    id="custom-single-value"
    aria-haspopup="listbox"
    aria-labelledby="custom-single-value"
    aria-expanded={props.selectProps.menuIsOpen}
    title={t('frontend.course.website_language')}
  >
    <components.SingleValue className="lang-switcher__native-single-value" {...props} />
  </SSingleValue>
);

const SSingleValue = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: nowrap;

  .lang-switcher__native-single-value {
    font-size: 0.875rem;
    margin-inline-start: 8px;
    position: static;
    display: inline;
    transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default CustomSingleValue;
