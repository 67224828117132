import styled from 'styled-components';

export const SRichEditorTextWrapper = styled.div`
  font-family: 'Inter', 'Product Sans', 'Helvetica', 'Arial', sans-serif !important;

  table[style] {
    width: 100% !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  img.emoji-img-inline {
    display: inline-block;
    margin-block-start: -0.3em;
    height: 1.2em;
    width: auto;
  }

  .notranslate {
    background-color: inherit !important;
  }
`;
